import {
  FC,
  useEffect,
  useRef,
  useState
} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import cx from 'classnames'
import InputMask from 'react-input-mask'
import ru from 'date-fns/locale/ru'
import { format, isValid, parse } from 'date-fns'
import { DayPicker, DateRange } from 'react-day-picker'
import { useOnClickOutside } from '../../helpers'
import {
  filterObject,
  formatDate,
  getCorrectValueForNumber,
  getNumberWithSpaces,
  isNumber,
  limitStr,
  removeSpacesInString
} from '../../utils'
import { Context } from '../../components'
import { ReactComponent as CalendarIcon } from '../../assets/icons/icon-calendar.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search.svg'
import { ReactComponent as EyeCrossedIcon } from '../../assets/icons/icon-eye-crossed.svg'
import { ReactComponent as EyeIcon } from '../../assets/icons/icon-eye.svg'
import { IInput, InputEventHandler } from './Input.d';
import 'react-day-picker/dist/style.css';
import './Input.scss'
import {logout} from "../../actions/users";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import Tooltip from "../../components/Tooltip";
import {trans} from "../../_locales";
import {currentUserLang} from "../../redux/slices/user";

const Input: FC<IInput> = ({
  className,
  label = '',
  tooltip = '',
  tooltipSide,
  type = 'text',
  placeholder = '',
  name = '',
  value,
  errorLabel = "Error",
  isErrorAbsolute = false,
  isDateField = false,
  isDateRange = false,
  isSearch = false,
  isInvalid = false,
  isDisabled = false,
  onChange = () => null,
  onBlur,
  isDirectCbInput = false,
  isPasswordSwitch,
  subBtn,
  isDateCustomIcon = false,
  color,
  dateCustomIcon,
  tip={},
  limitNumber,
  limitSymbols,
  required,
  beta = false,
  autoComplete=true,
  isIndentRight,
  dateSettings
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const language = useAppSelector(currentUserLang);
  const dayPickerBtnRef = useRef(null);
  const dayPickerRef = useRef(null);
  const dateFieldRef = useRef(null);
  const [selected, setSelected] = useState<Date>();
  const [range, setRange] = useState<DateRange>();
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [switchableType, setSwitchableType] = useState<string>(type);
  const [tipWrapper, setTipWrapper] = useState(tip);
  const [showTips, setShowTips] = useState(true);

  const switchPasswordType = () => {
    if (switchableType === 'password') {
      setSwitchableType('text');
    } else if (type === 'password') {
      setSwitchableType('password')
    }
  };

  const Tag = type === 'textarea' ? type : 'input';
  const SubBtnTag = subBtn?.href ? Link : 'button';
  const currentClassName = type === 'textarea' ? 'custom-textarea' :  "custom-input";
  const inputClassName = cx(
    currentClassName,
    className,
    {
      'is-invalid': isInvalid,
      'is-invalid-absolute': isErrorAbsolute,
      'is-disabled': isDisabled,
      'color--dark': color === 'dark',
      'is-data': isDateField || isDateRange,
      'custom-input--password-switch': isPasswordSwitch,
    }
  )

  useOnClickOutside(() => {
    if (isPopperOpen) setIsPopperOpen(false);
  }, [dayPickerRef, dayPickerBtnRef]);

  useEffect(() => {
    if (!value) {
      setSelected(undefined);
      return;
    }
    const date = parse(value, 'y-MM-dd', new Date());
    setSelected(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(()=> {
    if(Object.values(tip).length > 0 && typeof value === 'string') {
      const regex = new RegExp(value);
      setTipWrapper(filterObject(tip, (val:any, key:any) => regex.test(val)))
    }
  },[value])

  const handleDateBtnClick = () => {
    if(!isPopperOpen) setIsPopperOpen(!isPopperOpen);
  };

  const handleInputChange: InputEventHandler = (e) => {
    const currentValue = e.target.value;
    const isTypeNumber = currentValue && (type === 'number' || type === 'float');
    const valueWithoutSpaces = removeSpacesInString(currentValue);

    if (isTypeNumber && !isNumber(valueWithoutSpaces)) return;

    const validValue = isTypeNumber ?
      getCorrectValueForNumber(valueWithoutSpaces, type === 'float', limitNumber) : limitSymbols ?
            limitStr(currentValue, limitSymbols): currentValue;

    if (isDirectCbInput) {
      onChange(currentValue);
    } else {
      onChange({ [name]: validValue });
    }
    setShowTips(true);
  };

  const handleInputUpdate = (value:string) => {
    const currentValue = value;
    const isTypeNumber = currentValue && (type === 'number' || type === 'float');
    const valueWithoutSpaces = removeSpacesInString(currentValue);

    if (isTypeNumber && !isNumber(valueWithoutSpaces)) return;

    const validValue = isTypeNumber ?
      getCorrectValueForNumber(valueWithoutSpaces) : currentValue;

    if (isDirectCbInput) {
      onChange(currentValue);
    } else {
      onChange({ [name]: validValue });
    }
    setShowTips(false);
  };

  const handleDateInputChange: InputEventHandler = (e) => {
    onChange({ [name]: e.currentTarget.value });
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());

    if (isValid(date)) {
      setSelected(date);
      return;
    }

    setSelected(undefined);
  };

  const handleDateRangeInputChange: InputEventHandler = (e) => {
    onChange({ [name]: e.currentTarget.value });
    const rangeArray = e.currentTarget.value.split(' - ');
    const dateFrom = parse(rangeArray[0], 'y-MM-dd', new Date());
    const dateTo = parse(rangeArray[1], 'y-MM-dd', new Date());

    if (isValid(dateFrom) && isValid(dateTo)) {
      setRange({from: dateFrom, to: dateTo});
      return;
    }

    setRange(undefined);
  };

  const handleDaySelect = (date: Date) => {
    setSelected(date);
    if (date) {
      onChange({ [name]: formatDate(date) });
      setIsPopperOpen(false);
    } else {
      onChange({ [name]: '' });
    }
  };

  const handleRangeSelect = (range: DateRange) => {
    setRange(range);
    if (range.from && range.to) {
      onChange({ [name]: `${formatDate(range.from)} - ${formatDate(range.to)}` });
      setIsPopperOpen(false);
    } else if (range.from) {
      onChange({ [name]: `${formatDate(range.from)} - ${formatDate(range.from)}` });
    } else {
      onChange({ [name]: '' });
    }
  }

  const recoverPassword = () => {
    dispatch(logout()).then(()=> {
      navigate('/recover');
    })
    return
  }

  return (
    <div className={inputClassName}>
      {label && <span className="custom-input__label">{label}{required && <span className='red'>*</span>} {tooltip && <Tooltip text={tooltip} isRight={tooltipSide === 'right'} isLeft={tooltipSide==='left'}/>} {beta && <span className='beta'>beta</span>}</span>}
      <label ref={dayPickerBtnRef}>
        {
          isSearch &&
          <SearchIcon
            className="custom-input__icon"
            width={14}
            height={14}
          />
        }
        {
          (isDateField || (isDateRange && !isDateCustomIcon)) &&
          <CalendarIcon
            className="custom-input__icon"
            width={24}
            height={24}
          />
        }
        {
          (isDateRange && isDateCustomIcon) &&
            <img
                src={dateCustomIcon}
                className="custom-input__icon"
                width={24}
                height={24}
                alt=""
            />
        }
        {
          isDateField ?
            <InputMask
              ref={dateFieldRef}
              mask={'9999-99-99'}
              type={type}
              name={name}
              placeholder={placeholder}
              disabled={isDisabled}
              value={value}
              onChange={handleDateInputChange}
              onClick={handleDateBtnClick}
            />
            :
            isDateRange ?
            <InputMask
              mask={'9999-99-99 - 9999-99-99'}
              type={type}
              name={name}
              placeholder={placeholder}
              disabled={isDisabled}
              value={value}
              onChange={handleDateRangeInputChange}
              onClick={handleDateBtnClick}
            />
            :
            <Tag
              className='custom-input__field'
              type={(type === 'number' || type === 'float') ? 'text' : switchableType}
              name={name}
              placeholder={placeholder}
              disabled={isDisabled}
              value={(value && (type === 'number' || type === 'float')) ? getNumberWithSpaces(value) : value}
              onChange={handleInputChange}
              onBlur={onBlur}
              autoComplete={autoComplete ? 'on' : 'off'}
            />
        }
        {isPasswordSwitch && (
          <button
            className="custom-input__password-switch"
            type='button'
            aria-label='Переключить видимость пароля'
            onClick={switchPasswordType}
          >
            {switchableType === 'password' ? <EyeCrossedIcon width={18} height={18} /> : <EyeIcon width={18} height={18} />}
            
          </button>
        )}
      </label>
      {(Object.keys(tipWrapper).length === 1 && tipWrapper[Object.keys(tipWrapper)[0]] === value) ? '' : Object.keys(tipWrapper).length > 0 && value && showTips && <ul className="custom-input__tip">
        {
          Object.keys(tipWrapper).map((key:any, index:number)=> {
            return(
                <li key={index}><button onClick={()=>handleInputUpdate(tipWrapper[key])}>{tipWrapper[key]}</button></li>
            )
          })
        }
      </ul>}
      <div className="custom-input__error">{errorLabel}</div>
      {subBtn && (
        <SubBtnTag
          className="custom-input__sub-btn"
          type={subBtn.href ? undefined: 'button'}
          to={subBtn.href as string}
          onClick={subBtn.text===trans('Forgot password?', language) ? recoverPassword : subBtn.onClick}
        >
          {subBtn.text}
        </SubBtnTag>
      )}

      {
        isDateField && isPopperOpen &&
        <Context
          parentRef={dateFieldRef}
          isIndentRight={isIndentRight}
          onClose={() => setIsPopperOpen(false)}
        >
          <div className="custom-input__rdp" ref={dayPickerRef}>
            <div className="custom-input__rdp-inner">
              <DayPicker
                mode="single"
                defaultMonth={selected}
                selected={selected}
                locale={ru}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onSelect={handleDaySelect}
                numberOfMonths={1}
                fromDate={dateSettings && dateSettings.min ? dateSettings.min : undefined}
                toMonth={dateSettings && dateSettings.max ? dateSettings.max : undefined}
              />
            </div>
          </div>
        </Context>
      }

      {
        isDateRange && isPopperOpen &&
        <div className="custom-input__rdp" ref={dayPickerRef}>
          <div className="custom-input__rdp-inner">
            <DayPicker
              mode="range"
              defaultMonth={range?.from}
              selected={range}
              locale={ru}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onSelect={handleRangeSelect}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Input;
