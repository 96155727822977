import React, {FC, useCallback, useEffect, useState} from 'react'
import { Form, Loader } from '../../index'
import {AccessField, Btn, Input} from '../../../ui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  AccessList,
  getAccessList,
  getIsLoading,
  errorValue,
  removeError
} from '../../../redux/slices/access'
import { addAccess, fetchAccessList, removeAccess } from '../../../actions/access'
import { AnyEvent } from '../../../interfaces/common.d'
import { useShowError } from '../../../helpers'
import { IAddEmailModal } from './interfaces.d'
import './style.scss'

import iconSearch from '../../../assets/icons/icon-search.svg';
import {ModalName, openModal} from "../../../redux/slices/modals";
import iconEdit from "../../../assets/icons/edit.svg";
import CalendarIcon from "../../../assets/icons/icon-calendar.svg";
import {REG_EMAIL} from "../../../data";
import {addProjectUser, getFilterUser, getProjectUsers, getRoles} from "../../../actions/projects";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Select from "../../ui/Select";
import {currentUser as reduxCurrentUser, currentUserLang} from "../../../redux/slices/user";
import {trans} from "../../../_locales";
import {removeFromObjectByKey} from "../../../utils";
import {reduxRoles} from "../../../redux/slices/roles";

const AddAccessModal: FC<IAddEmailModal> = ({
  userId,
  projectId,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(getAccessList);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);
  const reduxUser =  useAppSelector(reduxCurrentUser);
  const reduxRolesList = useAppSelector(reduxRoles);

  const currentList = list ? list : [] as AccessList;
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const used_roles = permissions && permissions['roles'] ? permissions['roles'] : {};
  const [roles, setRoles] = useState(used_roles)

  useEffect(() => {
    if(userId && projectId) {
      dispatch(fetchAccessList({ userId, projectId }));
      if(!reduxRolesList) {
        dispatch(getRoles({projectId: Number(projectId)}))
      }
    }
  }, [userId, projectId, dispatch]);
  useEffect(()=> {
    if (reduxRolesList && Array.isArray(reduxRolesList)) {
      const temp: any = {}
      reduxRolesList.map((item: any) => {
        temp[item.name] = item.title;
      })
      setRoles(temp)
    }
  }, [reduxRolesList])

  useShowError(errorMessage as string, removeError);

  const handelAccessRemove = useCallback((evt: AnyEvent, email: string) => {
    evt.preventDefault();
    dispatch(removeAccess({ email, userId, projectId }));
  }, [dispatch, userId, projectId]);

  const handelAccessAdd = useCallback((email: string) => {
    dispatch(addAccess({ email, userId, projectId }));
  }, [dispatch, userId, projectId]);

  const [targetName, setTargetName] = useState('');
  const changeTargetName = (value:string)=> {
    setTargetName(value);
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.))/gm;
    if(regex.test(value)) {
      setTimeout(()=> {
        dispatch(getFilterUser({email: value})).then((r)=> {
          if(r.payload === 'User not found') {
            setShowListUser(false);
            return;
          }
          if(r.payload) {
            setListUser(r.payload);
            setShowListUser(true);
          }
        })
      },500)
    } else {
      setShowListUser(false);
    }
    // setData( {...data, event_target_name: targetName});
  }

  interface IUserProject {
    id?: number
    name?: string
    last_name?: string
    user_email: string
    user_role?: string,
    traffic_channel?: string
    traffic_name?: string
    traffic_type?: string
    last_seen?: string
    expires?: string | null
  }

  const [listUser, setListUser] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState<IUserProject>({id: -1, user_email: '', traffic_type: 'no_limit', traffic_channel: 'channel', expires: null});
  const [showListUser, setShowListUser] = useState(false);
  const [isShowRoot, setIsShowRoot] = useState(false);

  const choiceUser = (email:string, name:any, lastname:any) => {
    setShowListUser(false);
    setIsShowRoot(true);
    setTargetName(email);
    setCurrentUser({...currentUser, user_email: email, name: name, last_name: lastname});
  }

  const [role, setRole] = useState(currentUser.user_role ? currentUser.user_role : 'view');
  const changeRole = (response:object): void => {
    for(const key in response) {
      setRole(key)
    }
    // setNameProject({...nameProject, currency: e.currentTarget.value})
  }
  const [expires, setExpires] = useState(currentUser.expires ? currentUser.expires : '');

  const [trafficName, setTrafficName] = useState(currentUser.traffic_name ? currentUser.traffic_name : '');
  const [trafficType, setTrafficType] = useState(currentUser.traffic_type ? currentUser.traffic_type : 'no_limit');
  const [trafficChannel, setTrafficChannel] = useState(currentUser.traffic_channel ? currentUser.traffic_channel : '');
  const changeTraffic = (name:string, type:string, channel:string, index:number, email:string): void => {
    setTrafficName(name);
    setTrafficType(type);
    setTrafficChannel(channel);
    setTargetName(email);
    setCurrentUser({...currentUser, traffic_type: type, traffic_name: name, traffic_channel: channel, user_email: email});
  }

  const changeExpires = (value:any, projectId:number, userId:number, index:number) => {
    if(value) {
      setExpires(value);
      setCurrentUser({...currentUser, expires: value});
    } else {
      setExpires('');
      setCurrentUser({...currentUser, expires: null});
    }
  }
  const validateOne = (
    key: string,
    value: string,
  ) => {
    const currentValue =
      key === 'email' ? REG_EMAIL.test(value as string) : true;
    return {[key]: currentValue};
  };
  const getUserByEmail = createAsyncThunk(
    'user/getUserByEmail',
    async (
      _,
      { rejectWithValue, extra: API}: any
  ) => {
      try {
        const response = await API.get(`/api/profile/email/?email=${targetName}`);
        return response.data;
      } catch (e: any) {
        if(e.response.data.detail === 'User not found') {
          return rejectWithValue('')
        }
        return rejectWithValue(e.response.data.detail);
      }
    }
  );
  const [errorEmail, setErrorEmail] = useState(false)
  const onInvite = () => {
    if(validateOne('email', targetName)['email']) {
      setErrorEmail(false);
      dispatch(getUserByEmail()).then((response) => {
        if(response.payload&&response.payload.email) {
          const name = response.payload.name;
          const last_name = response.payload.last_name;
          const email = response.payload.email;
          choiceUser(email, name, last_name);
        } else {
          setShowListUser(false);
          setIsShowRoot(true);
        }
      })
    } else {
      setErrorEmail(true);
    }
  }

  const onReset = ()=> {
    setExpires('');
    setCurrentUser({...currentUser, expires: null, traffic_type: 'no_limit'});
  }
  const onSubmit = ()=> {
    let data:IUserProject = {
      user_email: targetName,
      user_role: role,
      traffic_name: trafficName,
      traffic_type: trafficType,
      traffic_channel: trafficChannel
    }
    if(expires !== '') {
      data = {...data, expires: expires}
    }

    dispatch(addProjectUser({data: data, projectId: projectId}))
    onClose('ADD_EMAIL')
  }
  const Tab = {
      include: trans('Include', language),
      exclude: trans('Exclude', language),
  }
  return (
    <>
      <div className={`add-email__block ${errorEmail ? 'is-invalid' : ''}`}>
        <div className={'input_section'}>
          <img src={iconSearch} alt="" className="add-email__icon"/>
          <input
              className={'input'}
              type='text'
              placeholder={trans("Enter the user's email", language)}
              value={targetName}
              onChange={(e)=>changeTargetName(e.target.value)} />
        </div>
        <div className="custom-input__error">{trans('Enter the correct E-mail', language)}</div>
        <div className={`add-email-search ${showListUser ? 'active' : ''}`}>
          {
            listUser.length > 0 ?
                listUser.map((item:any)=> (
                  <button key={item.id} className={'add-email-search__item'} onClick={()=>choiceUser(item.email, item.name, item.last_name)}>
                    <div className="add-email-search__name">{item.name} {item.last_name}</div>
                    <span className="add-email-search__email">{item.email}</span>
                  </button>
                ))
             : ''
          }
        </div>
      </div>
      {isShowRoot ?
        <div className={'add-email-root'}>
          {
            currentUser && currentUser.user_email ?
            <div className="add-email-root__user">
              <div className="add-email-root__name">{currentUser.name} {currentUser.last_name}</div>
              <span className="add-email-root__email">{currentUser.user_email}</span>
            </div>
                :
            <div className="add-email-root__user not-found">
              {trans('The user is not registered', language)}<br/>
              {trans('The invitation will be sent by email', language)}
            </div>
          }

          <div className={'input_section'}>
            <span className={'input_label'}>{trans('Role', language)}</span>
            <div className={'select_wrapper add-email__select'}>
              <Select selectActive={(roles as any)[role]} dataset={removeFromObjectByKey(roles, 'creator')} onChangeActive={changeRole} />
            </div>
          </div>
          {/*<div className={'input_section'}>*/}
          {/*  <span className={'input_label'}>{trans('Traffic limit', language)}</span>*/}
          {/*  <button className={'input edit-user__btn'} onClick={() =>dispatch(openModal({*/}
          {/*    name: ModalName.LIMIT_TRAFFIC,*/}
          {/*    data: {*/}
          {/*        projectId: projectId,*/}
          {/*        trafficType: currentUser.traffic_type,*/}
          {/*        trafficChannel: currentUser.traffic_channel,*/}
          {/*        trafficName: currentUser.traffic_name,*/}
          {/*        email: currentUser.user_email,*/}
          {/*        onChange: changeTraffic*/}
          {/*    }*/}
          {/*  }))}>*/}
          {/*    <span>{ currentUser.traffic_type === 'no_limit' ?*/}
          {/*        trans('No limit', language) :*/}
          {/*        `${(Tab as any)[`${currentUser.traffic_type}`]} -> ${currentUser.traffic_channel} = ${currentUser.traffic_name}`}</span>*/}
          {/*    <span className="edit-user__icon">*/}
          {/*      <img src={iconEdit} alt=""/>*/}
          {/*    </span>*/}
          {/*  </button>*/}
          {/*</div>*/}
          <div className={'input_section'}>
            <span className={'input_label'}>{trans('Access expiration date', language)}</span>
            <button className={'input edit-user__btn'} onClick={() =>dispatch(openModal({
              name: ModalName.ACCESS_TIME,
              data: {
                expires: expires,
                onChange: changeExpires
              }
            }))}>
              <span>{ currentUser.expires ? currentUser.expires :
                  trans('Unlimited', language)
                  }</span>
              <span className="edit-user__icon edit-user__icon_l">
                <img src={CalendarIcon} alt=""/>
              </span>
            </button>
          </div>
          <div className="add-email__action">
            <Btn
              type='reset'
              text={trans('Clear', language)}
              size='lg'
              widthAuto={false}
              onClick={onReset}
            />
            <Btn
              type='submit'
              text={trans('Add', language)}
              color='blue'
              widthAuto={false}
              size='lg'
              disabled={false}
              onClick={onSubmit}
            />
          </div>
        </div> :
        <div className="add-email__action">
          <Btn
            type='reset'
            text={trans('Cancel', language)}
            size='lg'
            widthAuto={false}
            onClick={onReset}
          />
          <Btn
            type='submit'
            text={trans('Invite', language)}
            color='light-blue'
            widthAuto={false}
            size='lg'
            disabled={false}
            onClick={onInvite}
          />
        </div>
      }


      {isLoading && <Loader />}
    </>
  );
};

export default AddAccessModal;
