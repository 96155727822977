import React, {FC, useEffect, useState} from 'react'
import cx from 'classnames'
import { useBodyScrollLock, useKeyPress } from '../../helpers'
import { Btn } from '../index'
import { Portal } from '../../components';
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg'
import { ReactComponent as CloseIconWhite } from '../../assets/icons/icon-close-white.svg'
import { IModalWrapper } from './Modal.d'
import './Modal.scss'
import TimeFilter from "../../components/ui/TimeFilter";
import Select from "../../components/ui/Select";
import iconModel from "../../assets/icons/model.svg";
import {getSelectChangesTitle, onChangeGeneralSelect} from "../../components/ui/Select/functions";
import {
    dashboardsSettings, setSettingEventTarget
} from "../../redux/slices/dashboards";
import {trans} from "../../_locales";
import iconWindow from "../../assets/icons/window.svg";
import iconPeriod from "../../assets/icons/period.svg";
import iconTarget from "../../assets/icons/target-icon.svg";
import {closeModal, ModalName, openModal} from "../../redux/slices/modals";
import IconFilterWhite from "../../assets/icons/icon-filter-white.svg";
import IconFilter from "../../assets/icons/icon-filter-blue.svg";
import {Groups, Models, Windows} from "../../data";
import {getEvents} from "../../actions/events";
import {getProjectAttributions} from "../../actions/project";
import {onLoadingSelectDate} from "../../pages/Projects/Project/Dashboards/functions";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {eventsData, ICurrentEvents} from "../../redux/slices/events";
import {currentUser, currentUserLang, userId} from "../../redux/slices/user";
import {getEventTitles} from "../../pages/Projects/Project/Reports/functions";

const Modal: FC<IModalWrapper> = ({
  className= '',
  title,
  children,
  isLast,
  onlyOne,
  onClose,
  projectId,
  filters,
  onChangeFilters=(filters:any)=>null
}) => {
  useKeyPress('Escape', onClose, isLast);
  useBodyScrollLock(onlyOne);

  const modalClassName = cx('modal', {[className]: className});
  const dispatch = useAppDispatch();
  const reduxEvents = useAppSelector(eventsData);
    const language = useAppSelector(currentUserLang);
    const settings = useAppSelector(dashboardsSettings);
    const reduxUser =  useAppSelector(currentUser);
    const reduxUserId =  useAppSelector(userId);

  const [periodStart, setPeriodStart] = useState(filters&&filters.periodStart?filters.periodStart:'');
    const [periodEnd, setPeriodEnd] = useState(filters&&filters.periodEnd?filters.periodEnd:'');
    const [model, setModel] = useState(filters&&filters.model?filters.model:'mtd');
    const [window, setWindow] = useState(filters&&filters.window?filters.window:30);
    const [categorize, setCategorize] = useState(filters&&filters.categorize?filters.categorize:'day');
    const [eventTarget, setEventTarget] = useState(filters&&filters.eventTarget?filters.eventTarget:'');
    const [selectDate, setSelectDate] = useState<string>(filters&&filters.selectDate?filters.selectDate:trans('Select the period', language));

    // useEffect(()=> {
    //     if(settings && Object.keys(settings).length > 0) {
    //         if (sessionStorage.getItem("settings")) {
    //             const sessionSettings = sessionStorage.getItem("settings");
    //             const parsed = sessionSettings && JSON.parse(sessionSettings);
    //             const temp = {
    //                 period: settings.period ? settings.period : parsed.period,
    //                 model: settings.model ? settings.model : parsed.model,
    //                 window: settings.window ? settings.window : parsed.window,
    //                 categorize: settings.categorize ? settings.categorize : parsed.categorize,
    //                 event: settings.event ? settings.event : parsed.event
    //             }
    //             sessionStorage.setItem("settings", JSON.stringify(temp));
    //         } else sessionStorage.setItem("settings", JSON.stringify(settings));
    //     } else if(sessionStorage.getItem("settings")) {
    //         const sessionSettings = sessionStorage.getItem("settings");
    //         const parsed = sessionSettings && JSON.parse(sessionSettings);
    //         parsed && dispatch(setSetting(parsed));
    //     }
    // }, [settings])

    const onChangeSelectDate = (response:any) => {
        setPeriodStart(response.start);
        setPeriodEnd(response.end);
        setSelectDate(`${response.start} - ${response.end}`);
    }
    const datasetModel = Models(language);
    const datasetWindow = Windows(language);
    const datasetCategorize = Groups(language);

    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {})
    const [filterEventTarget, setFilterEventTarget] = useState<Array<ICurrentEvents>>( filters&&filters.targetEvents?filters.targetEvents:[]);
    const [eventTargetActive, setEventTargetActive] = useState<Array<string>>( [])
    const onChangeEventTarget = (response:object) => {
        const {title, listActive} = getSelectChangesTitle(response, trans('All event targets', language));
        setEventTarget(title);
        setEventTargetActive(listActive);
    }
    useEffect(()=>{
        if(reduxEvents && Array.isArray(reduxEvents)) {
            const result:Array<ICurrentEvents> = [];
            reduxEvents.map((item:any)=> {
                const temp: ICurrentEvents = {} as ICurrentEvents;
                if(eventTargetActive.includes(item.event_target_name)) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                    result.push(temp);
                }
            })
            setFilterEventTarget(result);
        }
    }, [reduxEvents, eventTarget, eventTargetActive])
    useEffect(() => {
        if(filters && projectId) {
            dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
                if (r.payload && typeof r.payload.length === 'number') {
                    const temp:any = {};
                    r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                    setDatasetEventTarget(temp);
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    if(parseSettings && Array.isArray(parseSettings.events)) {
                        const {title, keys} = getEventTitles(parseSettings.events);
                        setEventTarget(title);
                        setEventTargetActive(keys);
                        setFilterEventTarget(parseSettings.events)
                    }
                }
            });
        }
    }, [dispatch, language, projectId]);
    const [firstLoading, setFirstLoading] = useState(true);

    useEffect(() => {
        if(firstLoading && filters && projectId) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
                if(r.payload) {
                    const sessionSettings = sessionStorage.getItem("settings");
                    const parseSettings = sessionSettings && JSON.parse(sessionSettings);
                    parseSettings && parseSettings.model ? setModel(parseSettings.model) : setModel(r.payload.attribution_model);
                    parseSettings && parseSettings.window ? setWindow(parseSettings.window) : setWindow(r.payload.attribution_window);
                    parseSettings && parseSettings.categorize ? setCategorize(parseSettings.categorize) : setCategorize('day');
                    if(parseSettings && parseSettings.period) {
                        setPeriodStart(parseSettings.period.start);
                        setPeriodEnd(parseSettings.period.end);
                        setSelectDate(`${parseSettings.period.start} - ${parseSettings.period.end}`);
                    } else {
                        onLoadingSelectDate({period: r.payload.attribution_period, setPeriodStart, setPeriodEnd, setValue: setSelectDate});
                    }
                }
            })
        }
    }, [projectId, reduxUser.id])

    // ------Filter--------
    const [filter, setFilter] = useState<Array<any>>(filters&&filters.filter?filters.filter:[]);
    const [filterActive, setFilterActive] = useState(!!(filters && filters.filter && Array.isArray(filters.filter) && filters.filter.length > 0));
    const onResetFilter = () => {
        setFilter([]);
        setFilterActive(false);
    }
    const onSubmitFilter = (response:any) => {
        setFilter(response);
        if(response.length > 0) {
            setFilterActive(true);
        }
        else setFilterActive(false);
        dispatch(closeModal(ModalName.FILTER))
    }
    //-------End Filter--------

    useEffect(()=> {
        if(filters) {
            const temp = {
                periodStart,
                periodEnd,
                model,
                window,
                categorize,
                eventTarget,
                targetEvents: filterEventTarget,
                selectDate,
                filter
            }
            onChangeFilters(temp);
        }
    }, [periodStart, periodEnd, model, window, categorize, eventTarget, filterEventTarget, selectDate, filter])

  return (
    <Portal selector={'#modal-root'}>
      <div className={modalClassName}>
        <div className="modal__wrapper">
          <div
            className="modal__overlay"
            tabIndex={-1}
            role="button"
            onClick={onClose}
            onKeyDown={onClose}
          />
          <div className="modal__content">
            <div className="modal__head">
              {title && <h2 className="title title--uppercase">{title}</h2>}
              {filters && <div className="dashboard-page-filter">
                <div className="dashboard-page-filter__date">
                  <TimeFilter selectActive={selectDate} onChange={onChangeSelectDate}/>
                </div>
                <div className="dashboard-page-filter__select">
                  <Select
                      selectActive={(datasetModel as any)[model]}
                      icon={iconModel}
                      dataset={datasetModel}
                      onChangeActive={(res:any) => onChangeGeneralSelect(res, (value:any) => {
                          setModel(value);
                      })}
                  />
                </div>
                <div className="dashboard-page-filter__select">
                  <Select selectActive={`${trans('Attribution window', language)}: ${window} ${trans('days', language)}`} icon={iconWindow} dataset={datasetWindow} onChangeActive={(res:any)=> {
                      onChangeGeneralSelect(res, (value:any) => {
                          const result = Number(value);
                          if(result === 15 || result === 30 || result === 60 || result === 90) {
                              setWindow(result);
                          }
                      });
                  }} />
                </div>
                <div className="dashboard-page-filter__select">
                  <Select
                      selectActive={`${trans('Scale', language)}: ${(datasetCategorize as any)[categorize]}`}
                      icon={iconPeriod}
                      dataset={datasetCategorize}
                      onChangeActive={(res:any)=> onChangeGeneralSelect(res, (value:any) => {
                          setCategorize(value);
                      })} />
                </div>
                <div className="dashboard-page-filter__select">
                  <Select
                      selectActive={eventTarget ? eventTarget : trans('All event targets', language)}
                      icon={iconTarget}
                      dataset={datasetEventTarget}
                      onChangeActive={onChangeEventTarget}
                      checkbox={true}
                      listActive={eventTargetActive}
                  />
                </div>
                <div className="dashboard-page-filter__auto">
                  <button className={`dashboard-page-filter__btn ${filterActive?'active':''}`} onClick={()=>{
                      projectId && dispatch(openModal({
                          name: ModalName.FILTER,
                          data: {
                            onSubmit: onSubmitFilter,
                            projectId: +projectId
                          }
                      }))
                  }}>
                      <img src={filterActive ? IconFilterWhite : IconFilter} alt=""/>
                      {trans('Filter', language)}
                  </button>
                  { filterActive && <Btn
                      className={'dashboard-page-filter__reset'}
                      isIconBtn={true}
                      size='sm'
                      color='transparent'
                      onClick={onResetFilter}
                      icon={{
                        Component: CloseIconWhite,
                        width: 16,
                        height: 16
                      }}
                  /> }
                </div>
              </div>}
            </div>

            <div className="modal__body">
              {children}
            </div>

            <Btn
              className={'modal__close-btn'}
              isIconBtn={true}
              size='sm'
              color='transparent'
              onClick={onClose}
              icon={{
                Component: CloseIcon,
                width: 16,
                height: 16
              }}
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
