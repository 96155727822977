import {createAsyncThunk} from '@reduxjs/toolkit'
import {setCheckingAuth} from '../redux/slices/user';
import {getAuthData, updateToken} from '../utils'
import {clearProjects} from '../redux/slices/projects';
import {clearCampaigns} from '../redux/slices/media';
import {clearPlacements} from '../redux/slices/placements';
import {closeModal, ModalName, openModal} from "../redux/slices/modals";
import {ReactComponent as IconHand} from "../assets/icons/icon-hand.svg";
import {openPopup} from "../redux/slices/popup";
import {PopupType} from "../data";

export const registration = createAsyncThunk(
  'user/registration',
  async (
    {data, repeatSend}:{data: FormData | JSON | any, repeatSend?:boolean},
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/auth/register/${repeatSend?'?repeat=true':''}`, data);
      if(response.data)
        dispatch(openModal({
          name: ModalName.INFORMATION,
          data: {
            title: `Здравствуйте!`,
            description: 'Регистрация почти завершена, на вашу почту выслана ссылка для подтверждения',
            icon: {
              Component: IconHand,
              width: 28,
              height: 28
            }
          }
        }));
      return response.data;
    } catch (e: any) {
      if(e.response.data && e.response.data.detail === 'The confirmation email has already been sent') {
        dispatch(openModal({
          name: ModalName.INFORMATION,
          data: {
            title: `Здравствуйте!`,
            description: 'Письмо с подтверждением уже отправлено. Проверьте вашу почту',
            icon: {
              Component: IconHand,
              width: 28,
              height: 28
            },
            subBtn: {
              text: 'Отправить ещё раз',
              onClick: ()=> {
                dispatch(registration({data, repeatSend:true}));
                dispatch(closeModal(ModalName.INFORMATION))
              }
            }
          }
        }));
        return rejectWithValue('');
      }
      if(e.response.data && typeof e.response.data.detail === 'string')
        return rejectWithValue(e.response.data.detail);
    }
  }
);

export const login = createAsyncThunk(
  'user/login',
  async (data: FormData | any, { rejectWithValue, extra: API}: any) => {
    try {
      const response = await API.post(`/api/auth/`, data);
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      const user_data = await API.get(`api/profile/`);
      return user_data.data
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async (
    _,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      // await API.post(`api/auth/logout/`);
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
    dispatch(clearProjects());
    dispatch(clearCampaigns());
    dispatch(clearPlacements());
    return;
  }
);

export const checkAuthorization = createAsyncThunk(
  'user/checkAuthorization',
  async (
    _,
    { getState, dispatch, rejectWithValue, extra: API}: any
  ) => {
    const { user } = getState()
    if (user.checkingAuth) return {};
    try {
      dispatch(setCheckingAuth(true));
      const response = await API.get(`/api/profile/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
});

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (
    data: JSON | any,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/auth/password/reset/`, data);
      if(response.status === 200) {
        dispatch(openModal({
          name: ModalName.INFORMATION,
          data: {
            title: `Восстановление пароля`,
            description: 'На вашу почту выслана ссылка для сброса пароля',
            icon: {
              Component: IconHand,
              width: 28,
              height: 28
            }
          }
        }))
      }
      return response;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const newPassword = createAsyncThunk(
  'user/newPassword',
  async (
    data: JSON | any,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/auth/password/new/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (
      _,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/profile/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const updateUserName = createAsyncThunk(
  'user/updateUserName',
  async (
    data: JSON | any,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/profile/change/name/`, data.data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getUserInfo());
      dispatch(closeModal(ModalName.NAME_EDITING));
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Имя и фамилия обновлены' }))
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async (
    data: JSON | any,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/profile/change/password/`, data.data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getUserInfo());
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'Пароль обновлен' }))
      dispatch(closeModal(ModalName.PASSWORD_EDITING));
      return response.data;
    } catch (e: any) {
      if(e.response.data.detail === 'Current passwords do not match') {
        dispatch(openPopup({ type: PopupType.ERROR, description: 'Проверьте введённые данные' }))
        return rejectWithValue(e.response.data.detail);
      }
      return rejectWithValue(e.response.data.message);
    }
  }
);
export const updateUserEmail = createAsyncThunk(
  'user/updateUserEmail',
  async (
    data: JSON | any,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/profile/change/email/`, data.data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getUserInfo());
      dispatch(openPopup({ type: PopupType.SUCCESS, title: 'E-mail обновлен' }))
      dispatch(closeModal(ModalName.EMAIL_EDITING));
      return response.data;
    } catch (e: any) {
      if(e.response.data.detail === 'Could not change email') {
        dispatch(openPopup({ type: PopupType.ERROR, description: 'Введите верный пароль' }))
        return rejectWithValue(e.response.data.detail);
      }
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);
export const updateUserTimeAndLang = createAsyncThunk(
  'user/updateUserTimeAndLang',
  async (
    {data, successTitle='Обновлено'}: {data: JSON | any, successTitle?:string},
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/profile/change/preferences/`, data);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getUserInfo());
      dispatch(openPopup({ type: PopupType.SUCCESS, title: successTitle}))
      return response.data;
    } catch (e: any) {
      if (typeof e.response.data.detail === 'string')
        dispatch(openPopup({ type: PopupType.ERROR, description: e.response.data.detail }));
      return rejectWithValue(e.response.data.detail);
    }
  }
);
export const logoutUsersDevices = createAsyncThunk(
  'user/logoutUsersDevices',
  async (
      _,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.delete(`/api/auth/revoke/`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(getUserInfo());
      dispatch(closeModal(ModalName.LAST_ACTIVITY));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.message);
    }
  }
);

export const checkExistUser = createAsyncThunk(
  'user/checkExistUser',
  async (
      email:string,
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.get(`/api/profile/email/?email=${email}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);