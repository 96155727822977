import {
    convertArraysToCsv, downloadCsv,
    findMaxValue,
    generateAndDownloadXlsx,
    getDefaultValue,
    isNumber,
    prepareArraysToXlsx
} from "../../../utils";
import {DefaultObject} from "../../../interfaces/common.d";
import {
    IColumnPreferences,
    IColumnPreferencesItem,
    IFilterColumnItem,
    IFilterColumns,
    ISearchColumns,
    ISortColumns
} from "../interfaces";
import {keyToSkip} from "../../../data";

/*
  Function groupByDate replaces the date with the desired format
  @param { data } - Array<Array<any>>
  |   a list which group
  @param { dateGrouping } - string (day || month || quarter || year)
  |   Grouping value
  @param { fieldTypes } - string[]
  |   list of all column types
> Return { Array<Array> } a list of grouped elements
*/
export const groupByDate:(arr:Array<Array<any>>, dateGrouping:string, fieldTypes:string[]) => (any[]) = (arr, dateGrouping, fieldTypes) => {
    const indexDate = fieldTypes.indexOf("date");
    if(indexDate > -1) {
        if(dateGrouping === 'week') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2]);
                        const year = date.getFullYear();
                        const month = date.getMonth()<9 ? `0${date.getMonth()+1}` : date.getMonth()+1;
                        const day = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

                        item[indexDate] = `${year}-${month}-${day < 10 ? `0${Math.abs(+day)}` : day}`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
        if(dateGrouping === 'month') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2]);
                        const year = date.getFullYear();
                        const month = date.getMonth()<9 ? `0${date.getMonth()+1}` : date.getMonth()+1 ;
                        item[indexDate] = `${year}-${month}-01`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
        if(dateGrouping === 'quarter') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2]);
                        const year = date.getFullYear();
                        const month = Math.ceil((date.getMonth()+1)/3)*3-2;
                        item[indexDate] = `${year}-${month>9 ? month : `0${month}`}-01`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
        if(dateGrouping === 'year') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2])
                        item[indexDate] = `${date.getFullYear()}-01-01`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
    }
    return [...arr];
}

/*
  Function groupByIndex groups an array
  @param { data } - Array<Array<any>>
  |   a list with lists of where to get the elements from
  @param { groupIndex } - number
  |   index of elements which need to group
  @param { dateGrouping } - string (day || month || year)
  |   Grouping value
  @param { types } - Array<string>
  |   list of all column types
> Return { [[]] } a list of elements' list
*/
export const groupByIndex = (data:Array<Array<any>>, dateGrouping:string, columns:Array<any>, aggColName:number[]) => {
    try {
        const arrays:(any)[][] = groupByDate(data, dateGrouping, columns.map((item:any)=>item.type));
        return aggregateData(arrays, columns,aggColName)
    } catch (e) {
        console.log('Error - ', e)
    }
    return [[],[]]
}

export const getCountPage = (dataLength:number, limit:number) => {
    return Math.ceil(dataLength / limit);
}


export const updateColIndexes = ({
    data,
    i,
    string=false,
    curIndexes,
    descending=false,
    format
}:{
    data:any[][],
    i:number,
    string?:boolean,
    curIndexes:number[],
    descending?:boolean,
    format?:string
}):number[] => {
    const indexes: number[] = JSON.parse(JSON.stringify(data.map((_, index) => index)));
    const temp = indexes.sort((a, b) => {
        if(!descending) {
            if(string) {
                if (data[a][i] > data[b][i]) return 1;
                if (data[a][i] < data[b][i]) return -1;
            } else {
                let valueA = +data[a][i];
                let valueB = +data[b][i];
                if (format === 'percent' && curIndexes[a] && curIndexes[b]) {
                    valueA /= curIndexes[a];
                    valueB /= curIndexes[b];
                }
                if (valueA > valueB) return 1;
                if (valueA < valueB) return -1;
            }
        } else {
            if(string) {
                if (data[a][i] < data[b][i]) return 1;
                if (data[a][i] > data[b][i]) return -1;
            } else {
                let valueA = +data[a][i];
                let valueB = +data[b][i];
                if (format === 'percent' && curIndexes[a] && curIndexes[b]) {
                    valueA /= curIndexes[a];
                    valueB /= curIndexes[b];
                }
                if (valueA < valueB) return 1;
                if (valueA > valueB) return -1;
            }
        }
        return 0;

    });
    const result: number[] = [];
    for (const item of temp) {
        result.push(curIndexes[item]);
    }
    return result;
}

export const onChangeSortTableCustom = ({
    type='default',
    index,
    data,
    types,
    curItemCount,
    columnPreferencesItem
}:{
    type:'ascending' | 'descending' | 'default',
    index:number,
    data:Array<any>,
    types:any,
    curItemCount?: number[],
    columnPreferencesItem: DefaultObject<any>
}):{newData: Array<Array<any>>, newItemCount: number[]} => {
    try {
        const format = types[index].toLowerCase();
        const string = format === 'string' || format === 'date';
        let newItemCount: number[] = [];
        if(type === 'ascending') {
            const tempData = JSON.parse(JSON.stringify(data));
            const result = tempData.slice().map((element:Array<any>, i:number) => {
                return { index: i, value: element[index] };
            }).sort(function (a:any, b:any) {
              if(string) {
                  if (a.value > b.value) return 1;
                  if (a.value < b.value) return -1;
              } else {
                  let valueA = Number(a.value);
                  let valueB = Number(b.value);
                  if (format === 'percent' && columnPreferencesItem.agg_function !== 'calc' && curItemCount && curItemCount[a.index] && curItemCount[b.index]) {
                      valueA /= curItemCount[a.index];
                      valueB /= curItemCount[b.index];
                  }
                  if (valueA > valueB) return 1;
                  if (valueA < valueB) return -1;
              }
              return 0;
            }).map((element:any) => {
                if(curItemCount)
                    newItemCount.push(curItemCount[element.index]);
                return tempData[element.index];
            });
            return {
                newData: result,
                newItemCount
            };
        }
        if(type === 'descending') {
            const tempData = JSON.parse(JSON.stringify(data));
            const result = tempData.slice().map((element:Array<any>, i:number) => {
                return { index: i, value: element[index] };
            }).sort(function (a: any, b: any) {
                if(string) {
                    if(a.value < b.value) return 1;
                    if(a.value > b.value) return -1;
                } else {
                    let valueA = Number(a.value);
                    let valueB = Number(b.value);
                    if(format === 'percent' && columnPreferencesItem.agg_function !== 'calc' && curItemCount && curItemCount[a.index] && curItemCount[b.index]) {
                        valueA /= curItemCount[a.index];
                        valueB /= curItemCount[b.index];
                    }
                    if(valueA < valueB) return 1;
                    if(valueA > valueB) return -1;
                }
                return 0;
            }).map((element:any) => {
                if(curItemCount)
                    newItemCount.push(curItemCount[element.index]);
                return tempData[element.index];
            });
            return {
                newData: result,
                newItemCount
            };
        }
        if(type ==='default') {
          const tempData = JSON.parse(JSON.stringify(data));
          const result = tempData.slice().sort(function (a:any, b:any) {
              if (a[0] < b[0]) {
                return 1;
              }
              if (a[0] > b[0]) {
                return -1;
              }
              return 0;
          })
          if(curItemCount) {
              newItemCount = updateColIndexes({
                  data: tempData,
                  i: index,
                  curIndexes: curItemCount,
                  string: string
              });
          }
          return {
              newData: result,
              newItemCount
          };
        }
    } catch (e) {
        console.log('Error - ', e)
    }
    return {
        newData: data ? data: [],
        newItemCount: curItemCount?curItemCount:[]
    };
}

export const changeCurrentPage = (
    event:any,
    page:number,
    per_page_max:number,
    setPageValue:any,
    setMiddlePage:any,
    setCurrentPage:any,
    limitChannelCustom:number=10,
    limit?:number,
    offset:number=0,
    onChangeOffset:any=(o:number, l:number) => null
)=> {
    if(limit && page+(offset/limitChannelCustom) === limit+1) {
        setPageValue('');
        if(offset > 0) {
            const currentPage = limit*limitChannelCustom / (limit*limitChannelCustom - offset);
            onChangeOffset(offset*currentPage, limit*limitChannelCustom*(currentPage+1))
            return;
        }
        onChangeOffset(limit*limitChannelCustom, limit*limitChannelCustom*2)
        return;
    }
    if(limit && page+(offset/limitChannelCustom) === offset/limitChannelCustom) {
        setPageValue('');
        if(offset > 0) {
            const currentPage = limit*limitChannelCustom / (limit*limitChannelCustom - offset);
            onChangeOffset((limit*limitChannelCustom - offset)*(currentPage-2), (limit*limitChannelCustom - offset)*(currentPage-1))
            return;
        }
        return;
    }
    if(page < 1 || page > per_page_max || isNaN(page)) {
        setPageValue('');
        return;
    }
    if(event.key === 'Enter') {
        Object.values(event.target.parentNode.parentNode.children).map((item:any) => {
            item.classList.remove('active');
            if((page < 4 || page > per_page_max - 2) && item.children.length > 0 && item.children[0].innerText == page) {
                item.classList.add('active');
            }
        })
        if(page > 3 && page < per_page_max - 1) {
            setMiddlePage(page);
            event.target.parentNode.parentNode.children[3].classList.add('active');
        }
        setCurrentPage(page);
        return;
    }
    Object.values(event.target.parentNode.parentNode.children).map((item:any) => (
        item.classList.remove('active')
    ))
    if(event.target.classList.contains('table-pagination-paginator__arrow')) {
        Object.values(event.target.parentNode.children[1].children).map((item:any) => {
            item.classList.remove('active');
            if((page < 4 || page > per_page_max - 2) && item.children.length > 0 && item.children[0].innerText == page) {
                item.classList.add('active');
            }
        })
        if(page > 3 && page < per_page_max - 1) {
            setMiddlePage(page);
            event.target.parentNode.children[1].children[3].classList.add('active');
        }
        setCurrentPage(page);
        return;
    }
    if(page > 3 && page < per_page_max - 1) {
        setMiddlePage(page);
        setCurrentPage(page);
        //TODO: handle error in this place when change page by arrows
        if(event.target.parentNode && event.target.parentNode.parentNode && event.target.parentNode.parentNode.children && event.target.parentNode.parentNode.children.length > 3)
            event.target.parentNode.parentNode.children[3].classList.add('active');
        return;
    }
    event.target.parentNode.classList.add('active');
    setCurrentPage(page);
    if(page !== per_page_max - 1)
        setMiddlePage(4);
}
export const getStringColIndexes = (types:string[], labels:string[]) => {
    const temp:number[] = [];
    types.map((value, index) => {
        if(value === 'string' || value === 'date')
            temp.push(index);
    })
    return temp;
}

export const getFuncIndexes = (funcs?:Array<IColumnPreferencesItem['agg_function']>, search?:string) => {
    const temp:number[] = [];
    funcs && search && funcs.map((value, index) => {
        if(value === search) temp.push(index);
    })
    return temp;
}

export const changeCol = ( oldValue:any, newValue:any, func:'skip'|'string'|'max'|'min'|'avg'|'sum'='sum', type:string) => {
    if (func === 'skip' || oldValue === keyToSkip)
        return keyToSkip;
    if ((func === 'sum' || func === 'avg') && newValue!== '') {
        if (isNumber(oldValue)) {
            const value = +oldValue + +newValue;
            if (type)
                return getDefaultValue(value, type);
            return value;
        }
        return `${oldValue}, ${newValue}`;
    }

    if (func === 'max') {
        if (isNumber(oldValue)) {
            if (+oldValue > +newValue) return getDefaultValue(oldValue, type);
            else return getDefaultValue(newValue, type);
        }
        return oldValue > newValue ? oldValue : newValue;
    }
    if (func === 'min') {
        if (isNumber(oldValue)) {
            if (+oldValue < +newValue) return getDefaultValue(oldValue, type);
            else return getDefaultValue(newValue, type);
        }
        return oldValue < newValue ? oldValue : newValue;
    }
    if(func === 'string') {
        return newValue;
    }
    return keyToSkip;
}

export function aggregateData(
    data:(any)[][],
    column:any[],
    aggColIndexes:number[]
) {
    try {
        const resultMap = new Map();
        const extendMap:any = {};
        const aggIndexes:number[] = aggColIndexes;
        const columnNames:string[] = [];
        const calcIndexes:number[] = [];
        const otherIndexes:number[] = [];
        const totalInfo:any = {
            count: 0,
            data: []
        };
        column.map((item:any, index:number)=> {
            columnNames.push(item.name)
            if(item.agg_function === 'calc')
                calcIndexes.push(index);
            else otherIndexes.push(index);
        });

        for (const row of data) {
            const arrKeys:string[] = [];
            aggIndexes.map(item => {
                arrKeys.push(row[item]);
            })
            const key = arrKeys.join(',');
            const existingRow = resultMap.get(key);
            const existingExtend = extendMap[key];

            if (existingRow) {
                otherIndexes.map((key, i)=> {
                    if(!aggIndexes.includes(key)) {
                        if(column[key] && column[key].agg_function !== 'calc') {
                            existingRow[i] = changeCol(existingRow[i], row[i], column[key].agg_function, column[key].type);
                            totalInfo.data[i] = changeCol(totalInfo.data[i], row[i], column[key].agg_function, column[key].type);
                        }
                    }
                })
                existingExtend.count+=1;
            } else {
                const value:any = [];
                otherIndexes.map((key, index)=> {
                    if(column[key] && (column[key].type == 'string' || column[key].agg_function==='skip') && !aggIndexes.includes(key)) {
                        totalInfo.data[index] = keyToSkip;
                        value.push(keyToSkip);
                    }
                    else if(column[key] && column[key].agg_function === 'calc') {
                        //skip
                    }
                    else if(column[key] && (column[key].type === 'float' || column[key].type === 'percent' || column[key].type === 'currency')) {
                        value.push(getDefaultValue(row[index], column[key].type));
                        totalInfo.data[index] = changeCol(totalInfo.data[index] ? totalInfo.data[index] : 0, row[index], column[key].agg_function, column[key].type);
                    } else if(column[key]) {
                        if(column[key] && (column[key].type == 'string' || column[key].type == 'date'))
                            totalInfo.data[index] = '';
                        else
                            totalInfo.data[index] = changeCol(totalInfo.data[index] ? totalInfo.data[index] : 0, row[index], column[key].agg_function, column[key].type);
                        value.push(row[index]);
                    }
                })
                resultMap.set(key, value);
                extendMap[key] ={count:1};
            }
            totalInfo.count++;
        }
        if(calcIndexes.length > 0) {
            Array.from(resultMap.values()).map((row, i)=> {
                calcIndexes.map((index)=> {
                    if(column[index] && column[index].agg_function === 'calc') {
                        if(column.length !== row.length) {
                            row.splice(index, 0, 0);
                            if(i===0) {
                                totalInfo.data.splice(index, 0, 0);
                            }
                        }
                    }
                })
                calcIndexes.map((index)=> {
                    if(column[index] && column[index].agg_function === 'calc') {
                        const calcColumns = (item:any, i:number) => {
                            const indexes: Array<number> = item.fields.map((item: string) => columnNames.indexOf(item));
                            const operation = item.operation;
                            const type = item.type;

                            if (operation === 'sum') {
                                let result = 0;
                                indexes.map(index => {
                                    if (index !== -1 && isNumber(row[index])) {
                                        result += +row[index];
                                    }
                                })
                                totalInfo.data[i] = changeCol(totalInfo.data[i], result, 'sum', type);
                                return changeCol(row[i], result, 'sum', type);
                            }
                            if (operation === 'dif') {
                                let result = 0;
                                indexes.map((key: number, index: number) => {
                                    if (key !== -1 && isNumber(row[key])) {
                                        if (index === 0) result = +row[key];
                                        else result -= +row[key];
                                    }
                                })
                                totalInfo.data[i] = changeCol(totalInfo.data[i], result, 'sum', type);
                                return changeCol(row[i], result, 'sum', type);
                            }
                            if (operation === 'multi') {
                                let result = 1;
                                indexes.map(key => {
                                    if (isNumber(row[key])) {
                                        result *= +row[key];
                                    }
                                })
                                totalInfo.data[i] = changeCol(totalInfo.data[i], result, 'sum', type);
                                return getDefaultValue(result, type);
                            }
                            if (operation === 'div') {
                                let result: number = 1;
                                indexes.map((key, index) => {
                                    const value = row[key];
                                    if (isNumber(value)) {
                                        if (index === 0) {
                                            result = +value;
                                        } else {
                                            if (!+value) {
                                                result = 0;
                                            } else if (+value !== 0 && result)
                                                result /= +value;
                                        }
                                    }
                                })
                                totalInfo.data[i] = changeCol(totalInfo.data[i], result, 'sum', type);
                                return getDefaultValue(result, type);
                            }
                            if (operation === 'div_multi') {
                                let result: number = 1;
                                indexes.map((key, index) => {
                                    const value = row[key];
                                    if (isNumber(value)) {
                                        if (index === 0) {
                                            result = +value;
                                        } else {
                                            if (!+value) {
                                                result = 0;
                                            } else if (+value !== 0 && result)
                                                result /= +value;
                                        }
                                    }
                                })
                                result *= 1000;
                                totalInfo.data[i] = changeCol(totalInfo.data[i], result, 'sum', type);
                                return getDefaultValue(result, type);
                            }
                            if (operation === 'roi') {
                                let result: number = 1;
                                indexes.map((key, index) => {
                                    const value = row[key];
                                    if (isNumber(value)) {
                                        if (index === 0) {
                                            result = +value;
                                        } else {
                                            if (!+value) {
                                                result = 0;
                                            } else if (+value !== 0 && result)
                                                result = (result - +value) / +value;
                                        }
                                    }
                                })
                                totalInfo.data[i] = changeCol(totalInfo.data[i], result, 'sum', type);
                                return getDefaultValue(result, type);
                            }
                        }
                        row[index] = calcColumns(column[index], index);
                    }
                })
            })
        }

        return [Array.from(resultMap.values()), Object.values(extendMap).map((item:any)=>item.count), totalInfo];
    } catch (e) {
        console.log('Error - ', e);
        return [[],[1]];
    }
}

export const getTotalValues = (data:any[][], column:any[], itemCountPercent:number[]) => {
    const result: any[] = [];
    const countItems: number[] = []
    column.map((item: any, index: number) => {
        result[index] = 0;
        countItems[index] = 0;
    })

    data.map((row: any[], rowIndex: number) => {
        row.map((value: any, key) => {
            if (column[key] && (column[key].agg_function === 'avg' || column[key].type === 'percent')) {
                countItems[key] += 1;
            }
            if (isNumber(value)) {
                let temp = +value;
                if (column[key] && column[key].agg_function === 'avg') temp /= +itemCountPercent[rowIndex];
                result[key] += temp;
            } else if (value !== keyToSkip) result[key] = '';
            else result[key] = keyToSkip;
        })
    })

    countItems.map((item: number, key: number) => {
        if (item) {
            result[key] /= item;
        }
    })

    return result;
}


export const getColorValue = (value:number, targetPrice:number) => {
    if (value > targetPrice) {
        const percentage = (value / targetPrice) * 100;
        if (percentage > 150) return 'red';
        return 'yellow'
    }
    return '';
}


export const getMaxTargetPrice = (list:Array<any>) => {
    if (list && list.length > 0) {
        return findMaxValue(list.map((item) => Number(item.event_target_price)))
    }
    return 0;
}

export const handleMouseMove = (e:any, cur:any, setting:DefaultObject<any> = {}, defaultWidthCol:number = 0, onChangeSetting:any, minWidthCol=150) => {
    if (!cur)
        return;
    e = e || window.event;
    let nx = e.clientX - cur.x;
    if (nx < minWidthCol) nx = minWidthCol;
    const temp = JSON.parse(JSON.stringify(setting));
    temp[cur.index] = nx;
    onChangeSetting(temp);

    cur.el.style.width = nx + 'px';
    cur.el.style.maxWidth = nx + 'px';
    cur.el.style.minWidth = nx + 'px';
    cur.elements.forEach((item: any) => {
        item.style.width = nx + 'px';
        item.style.maxWidth = nx + 'px';
        item.style.minWidth = nx + 'px';
    })
    if (cur.stretch_indexes) {
        let value = 0;
        cur.stretch_indexes.forEach((item: any) => {
            value += item.clientWidth;
        })
        cur.stretch.style.width = value + 'px';
        cur.stretch.style.maxWidth = value + 'px';
        cur.stretch.style.minWidth = value + 'px';
    }
    if (defaultWidthCol && Object.values(temp).length > 0) {
        let value = defaultWidthCol;
        Object.values(temp).map((item: any) => {
            if (isNumber(item)) value += (item - minWidthCol);
        })
        cur.head.style['min-width'] = value + 'px';
        cur.body.style['min-width'] = value + 'px';
        if (cur.total) cur.total.style['min-width'] = value + 'px';
    }
    (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
}

export interface IFunctionDownloadReport {
    type:'csv'|'xlsx',
    name:string,
    dataWrapper:Array<Array<any>>,
    setAggUpdate:any,
    dateGrouping?:string,
    itemCountPercent:Array<any>,
    titles:string[],
    aggFunctions?:Array<IColumnPreferencesItem['agg_function']>,
    columnPreferences?:IColumnPreferences,
    fieldTypes: Array<string>,
    currency?:'USD'|'EUR'|'RUB',
    prepareXlsx?:any,
    prepareCsv?:any,
    downloadXlsx?:any,
    downloadCsvFile?:any,
    metricChoice?:string[],
    aggColIndexesDefault?:number[],
    metricColIndexes?:number[]
}

export const downloadReport = ({
    type = 'csv',
    name='report',
    dataWrapper,
    setAggUpdate,
    dateGrouping,
    itemCountPercent,
    titles,
    aggFunctions,
    columnPreferences,
    fieldTypes,
    currency='RUB',
    prepareXlsx=prepareArraysToXlsx,
    prepareCsv=convertArraysToCsv,
    downloadXlsx=generateAndDownloadXlsx,
    downloadCsvFile=downloadCsv,
    metricChoice,
    aggColIndexesDefault,
    metricColIndexes
}: IFunctionDownloadReport) => {
    if (dataWrapper.length > 0) {
        setAggUpdate(true);
        const aggFunctionsDefault: Array<IColumnPreferencesItem['agg_function']> = aggFunctions ? aggFunctions : [];
        if (Array.isArray(itemCountPercent)) {
            const prepareTitles = titles.map(item => {
                const temp = columnPreferences && columnPreferences.find(column => column.name === item);
                if(temp && temp.title) return temp.title;
                return item;
            })
            try {
                if (type === 'xlsx') {
                    const prepareXlsxData = prepareXlsx(dataWrapper, prepareTitles, itemCountPercent, fieldTypes, aggFunctionsDefault, currency, metricChoice, aggColIndexesDefault, metricColIndexes);
                    downloadXlsx(prepareXlsxData, `${name}_${new Date().toISOString().split('T')[0]}`)
                }
                if (type === 'csv') {
                    const prepareCsvData = prepareCsv(dataWrapper, prepareTitles, itemCountPercent, fieldTypes, aggFunctionsDefault, currency, metricChoice, aggColIndexesDefault, metricColIndexes);
                    downloadCsvFile(prepareCsvData, type, `${name}_${new Date().toISOString().split('T')[0]}`)
                }
            } catch (e) {
                console.log(e);
            }
        }
        setAggUpdate(false);
    }
}


export const downloadChartReport = ({
    type = 'csv',
    name='report',
    dataWrapper,
    titles,
    aggFunctions,
    columnPreferences,
    fieldTypes,
    currency='RUB',
    prepareXlsx=prepareArraysToXlsx,
    prepareCsv=convertArraysToCsv,
    downloadXlsx=generateAndDownloadXlsx,
    downloadCsvFile=downloadCsv
}: {
    type:'csv'|'xlsx',
    name:string,
    dataWrapper:Array<Array<any>>,
    titles:string[],
    aggFunctions?:Array<'skip'|'string'|'max'|'min'|'avg'|'sum'>,
    columnPreferences?:any,
    fieldTypes: Array<string>,
    currency?:'USD'|'EUR'|'RUB',
    prepareXlsx?:any,
    prepareCsv?:any,
    downloadXlsx?:any,
    downloadCsvFile?:any
}) => {
    if (dataWrapper.length > 0) {
        const aggFunctionsDefault: Array<'skip' | 'string' | 'max' | 'min' | 'avg' | 'sum'> = aggFunctions ? aggFunctions : [];
        const prepareTitles = titles.map(item => columnPreferences && columnPreferences[item] && columnPreferences[item].title ? columnPreferences[item].title : item)
        try {
            if (type === 'xlsx') {
                const prepareXlsxData = prepareXlsx(dataWrapper, prepareTitles, [], fieldTypes, aggFunctionsDefault, currency);
                downloadXlsx(prepareXlsxData, `${name}_${new Date().toISOString().split('T')[0]}`)
            }
            if (type === 'csv') {
                const prepareCsvData = prepareCsv(dataWrapper, prepareTitles, [], fieldTypes, aggFunctionsDefault, currency);
                downloadCsvFile(prepareCsvData, type, `${name}_${new Date().toISOString().split('T')[0]}`)
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export interface IChangeFilter {
    dataCopy: any[],
    search:ISearchColumns,
    filter:IFilterColumns,
    sort:ISortColumns,
    itemCountPercentCopy: any[],
    columnPreferences: any[],
    fieldTypes: string[]
}
export const changeFilter = ({
    search={},
    filter={},
    sort={},
    dataCopy,
    itemCountPercentCopy,
    columnPreferences,
    fieldTypes
}: IChangeFilter
) => {
    let tempData = JSON.parse(JSON.stringify(dataCopy));
    let tempCountPercent = JSON.parse(JSON.stringify(itemCountPercentCopy));
    const sumNotInclude: any = {};
    let countNotInclude = 0;
    const indexesAvg: Array<number> = columnPreferences.map((item: any, index: number) => {
        sumNotInclude[index] = 0;
        return index;
    })
    const onHandleFilter = (item: any, condition: boolean, key: number) => {
        if (condition) {
            return true;
        }
        indexesAvg.map(i => {
            if (sumNotInclude[i]) sumNotInclude[i] += +item[i];
            else sumNotInclude[i] = +item[i];
        })
        tempCountPercent[key] = undefined;
        ++countNotInclude;
        return false;
    }

    Object.keys(search).map((key) => {
        if (search[key] !== '') {
            const regex = new RegExp(search[key].toLowerCase());
            tempData = tempData.filter((item: any, index: number) => onHandleFilter(item, regex.test(item[key].toLowerCase()), index));
        }
    })
    tempCountPercent = tempCountPercent.filter((item: any) => item !== undefined)


    const tempFilter = filter;
    Object.keys(tempFilter).map((key) => {
        if (tempFilter[key]) {
            if (tempFilter[key].condition === 'default') {
                delete tempFilter[key];
            }

            let value = +tempFilter[key].value;
            const percent = columnPreferences[+key] && columnPreferences[+key].type === 'percent';
            if (percent) {
                value /= 100;
            }
            if (tempFilter[key].condition === 'more')
                tempData = tempData.filter((item: any, index: number) => onHandleFilter(item, (percent ? (+item[key] / +tempCountPercent[index]) : +item[key]) > value, index));
            if (tempFilter[key].condition === 'less') {
                tempData = tempData.filter((item: any, index: number) => onHandleFilter(item, (percent ? (+item[key] / +tempCountPercent[index]) : +item[key]) < value, index));
            }
            if (tempFilter[key].condition === 'equal')
                tempData = tempData.filter((item: any, index: number) => onHandleFilter(item, (percent ? (+item[key] / +tempCountPercent[index]) : +item[key]) === value, index));
            if (tempFilter[key].condition === 'no_contain') {
                tempData = tempData.filter((item: any, index: number) => onHandleFilter(item, (percent ? (+item[key] / +tempCountPercent[index]) : +item[key]) != value, index));
            }
        }
    })

    tempCountPercent = tempCountPercent.filter((item: any) => item !== undefined);

    if (Object.keys(sort).length > 0) {
        const key = Object.keys(sort)[0];
        if (isNumber(key) && sort[key] !== 'default') {

            const {newData, newItemCount} = onChangeSortTableCustom({
                type: sort[key],
                index: +key,
                data: tempData,
                types: fieldTypes,
                curItemCount: tempCountPercent.filter((item: any) => item !== undefined),
                columnPreferencesItem: columnPreferences[+key] ? columnPreferences[+key] : {}
            });

            tempData = newData;
            tempCountPercent = newItemCount;
        }
    }

    return {
        sumNotInclude,
        countNotInclude,
        tempCountPercent: tempCountPercent.filter((item: any) => item !== undefined),
        tempData,
        tempTotal: getTotalValues(tempData, columnPreferences, tempCountPercent.filter((item: any) => item !== undefined)),
        tempFilter
    }
}