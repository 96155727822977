import {useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";

import './style.scss';
import {FC, useEffect} from "react";
import {IDashboardFullscreen} from "./interfaces.d";
import DashboardsTable, {IDashboardsTable} from "../../../pages/Projects/Project/Dashboards/components/Table";
import DashboardsChart, {IDashboardsChart} from "../../../pages/Projects/Project/Dashboards/components/Charts";

const isIDashboardsTable = (data: IDashboardFullscreen['data']): data is IDashboardsTable => {
    return (data as IDashboardsTable).noActual !== undefined;
}

const isIDashboardsChart = (data: IDashboardFullscreen['data']): data is IDashboardsChart => {
    return (data as IDashboardsChart).positionLabels !== undefined;
}

const DashboardFullscreen:FC<IDashboardFullscreen> = ({
    onClose,
    data,
    filters,
    type='table'
}) => {
    const language = useAppSelector(currentUserLang);
    const keyForSession = 'settings_table_temp_modal';

    useEffect(() => {
        const generalSession = sessionStorage.getItem('settings_table_temp');
        if(generalSession) {
            const parse = JSON.parse(generalSession);
            if(parse[data.name]) {
                sessionStorage.setItem(keyForSession, JSON.stringify(parse))
            }
        }
    }, []);

    return(
        <div className={'dashboard-fullscreen'}>
            {type === 'table' &&
                <DashboardsTable
                    name={data.name}
                    projectId={data.projectId}
                    periodStart={filters?filters.periodStart:data.periodStart}
                    periodEnd={filters?filters.periodEnd:data.periodEnd}
                    defaultName={"defaultName" in data ? data.defaultName : undefined}
                    subDescription={"subDescription" in data ? data.subDescription : undefined}
                    model={filters?filters.model:data.model}
                    window={filters?filters.window:data.window}
                    categorize={filters?filters.categorize:data.categorize}
                    filter={filters?filters.filter:data.filter}
                    targetEvents={filters?filters.targetEvents:data.targetEvents}
                    serverGroup={"serverGroup" in data ? data.serverGroup : undefined}
                    noActual={"noActual" in data ? data.noActual : false}
                    setActual={"setActual" in data ? data.setActual : undefined}
                    queue={data.queue}
                    weight={data.weight}
                    offAgg={"offAgg" in data ? data.offAgg : undefined}
                    defaultAgg={"defaultAgg" in data ? data.defaultAgg : undefined}
                    defaultFilter={"defaultFilter" in data ? data.defaultFilter : undefined}
                    defaultSort={"defaultSort" in data ? data.defaultSort : undefined}
                    defaultActiveSearch={"defaultActiveSearch" in data ? data.defaultActiveSearch : undefined}
                    defaultActiveFilter={"defaultActiveFilter" in data ? data.defaultActiveFilter : undefined}
                    defaultActiveSort={"defaultActiveSort" in data ? data.defaultActiveSort : undefined}
                    defaultLimit={"defaultLimit" in data ? data.defaultLimit : undefined}
                    defaultOffset={"defaultOffset" in data ? data.defaultOffset : undefined}
                    metricInclude={"metricInclude" in data ? data.metricInclude : undefined}
                    defaultMetric={"defaultMetric" in data ? data.defaultMetric : undefined}
                    defaultRowsOnPage={"defaultRowsOnPage" in data ? data.defaultRowsOnPage : undefined}
                    tabs={"tabs" in data ? data.tabs : undefined}
                    disableName={"disableName" in data ? data.disableName : false}
                    fullscreen={true}
                    isBigData={true}
                    defaultResponse={data.defaultResponse}
                    isFirstLoading={!filters}
                    hideTotal={"hideTotal" in data ? data.hideTotal : undefined}
                    keyForSession={keyForSession}
                />
            }
            {type === 'chart' && isIDashboardsChart(data) &&
                <DashboardsChart
                    name={data.name}
                    titleDefault={"titleDefault" in data ? data.titleDefault : ''}
                    projectId={data.projectId}
                    periodStart={filters?filters.periodStart:data.periodStart}
                    periodEnd={filters?filters.periodEnd:data.periodEnd}
                    model={filters?filters.model:data.model}
                    window={filters?filters.window:data.window}
                    categorize={filters?filters.categorize:data.categorize}
                    filter={filters?filters.filter:data.filter}
                    targetEvents={(()=>{
                        if(filters&&Array.isArray(filters.targetEvents))
                            return filters.targetEvents;
                        if(data.targetEvents && Array.isArray(data.targetEvents))
                            return data.targetEvents;
                    })()}
                    type={"type" in data ? data.type : 'Line'}
                    labelIndex={"labelIndex" in data ? data.labelIndex : 0}
                    nameIndex={"nameIndex" in data ? data.nameIndex : undefined}
                    dataIndexes={"dataIndexes" in data ? data.dataIndexes : []}
                    positionLabels={"positionLabels" in data ? data.positionLabels : 'top'}
                    queue={data.queue}
                    weight={data.weight}
                    isOpenFullScreen={"isOpenFullScreen" in data ? data.isOpenFullScreen : true}
                    defaultResponse={data.defaultResponse}
                    isFirstLoading={data.isFirstLoading}
                />

            }
        </div>
    )
}

export default DashboardFullscreen;