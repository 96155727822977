import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {creatSource, fetchSources, fetchSourceById, updateSource, getBaseSources} from '../../actions/source';

export interface Source {
  id: number
  name: string
  host: string
  custom_click_param: string
  custom_impression_param: string
  project_id: number
  author_id: number
  created: string
}

export interface BaseSource {
    id: number,
    name: string,
    click_params: string,
    impression_params: string
}

export interface SourcesState {
  sources: Source[]
  newSource: Source
  isLoading: boolean
  error: string | null
  baseSources: BaseSource[]
}

const initialState: SourcesState = {
  sources: [] as Source[],
  baseSources: [] as BaseSource[],
  newSource: {} as Source,
  isLoading: false,
  error: null
}

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {
    setSources: (state, action) => {
      state.sources = action.payload;
    },
    clearNewSource: (state) => {
      state.newSource = {} as Source;
    },
    clearSources: (state) => {
        state.sources = []
    },
    clearBaseSources: (state) => {
        state.baseSources = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      creatSource.fulfilled,
      (
        state,
        action: PayloadAction<Source>
      ) => {
        // state.sources.push(action.payload);
        state.newSource = action.payload;
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (creatSource.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      creatSource.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      fetchSources.fulfilled,
      (state, action: PayloadAction<Source[] | null>) => {
        state.sources = action.payload ? action.payload : [] as Source[];
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (fetchSources.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      fetchSources.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      fetchSourceById.fulfilled,
      (state) => {
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (fetchSourceById.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      fetchSourceById.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      updateSource.fulfilled,
      (state, action: PayloadAction<Source>) => {
        const prev = state.sources.find((project) => action.payload && action.payload.id && project.id  === action.payload.id);
        const index = prev ? state.sources.indexOf(prev) : -1;

        if (prev) {
          state.sources[index] = action.payload;
        }

        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (updateSource.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updateSource.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      getBaseSources.fulfilled,
      (state, action: PayloadAction<BaseSource[] | null>) => {
        state.baseSources = action.payload ? action.payload : [] as BaseSource[];
        state.isLoading = false;
        state.error = null;
      });
    builder.addCase
    (getBaseSources.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      getBaseSources.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
})

export const { setSources, clearNewSource, clearSources, clearBaseSources } = sourcesSlice.actions

export const sources = createSelector(
  (state: RootState) => state.sources,
  (sources) => sources.sources
)

export const newSource = createSelector(
  (state: RootState) => state.sources,
  (sources) => sources.newSource
)

export const baseSources = createSelector(
  (state: RootState) => state.sources,
  (sources) => sources.baseSources
)

export default sourcesSlice.reducer
