import {ICustomReport} from "./interfaces.d";
import {DefaultObject} from "../../interfaces/common.d";
import {isContainsAnyFromArrayInOtherArray} from "../../utils";


export const prepareFilterData = (data:Array<any>, type_value?: 'array' | 'one') => {
    const result: any[] = [];
    data.filter((item: any) => item.name).map((item: any, index: number) => {
        if (item.name && item.condition && item.value && item.value.length > 0 && (item.value[0] || typeof item.value[0] === 'number')) {
            let value = type_value === 'array' || typeof item.value === 'string' || typeof item.value === 'number' ? item.value : item.value[0];
            if (type_value !== 'array' && item.type === 'percent')
                value /= 100;
            result.push({'name': item.name, 'condition': item.condition, 'value': value});
            // result.push(`name=${item.name};condition=${item.condition};value=${item.value[0]}`);
        } else if (item.name && item.value && item.value.length > 0 && (item.value[0] || typeof item.value[0] === 'number')) {
            let value = type_value === 'array' || typeof item.value === 'string' || typeof item.value === 'number' ? item.value : item.value[0];
            if (type_value !== 'array' && item.type === 'percent')
                value /= 100;
            result.push({'name': item.name, 'condition': 'equal', 'value': value});
        }
    })

    return result;
}


export const prepareSortData = (data:Array<any>) => {
    const result: { name: string, value: string }[] = [];
    data.map((item: any, index: number) => {
        if (item.name && (item.value === 'ascending' || item.value === 'descending')) {
            result.push({'name': item.name, 'value': item.value});
        }
    })

    return result;
}


export const isCheckRequiredSelect = ({
    type,
    selectAggNames = [],
    selectMetricsNames = [],
    required
}:{
    type:ICustomReport["type"],
    selectAggNames: string[],
    selectMetricsNames: string[],
    required?: string[]
}) => {
    if (selectAggNames.length === 0 && selectMetricsNames.length === 0) return false;
    const isBothSelectNotEmpty: DefaultObject<boolean> = {
        general_custom_table: true,
        ecommerce: true,
        media_campaign_effectivity: true
    }
    if (isBothSelectNotEmpty[type] && (selectAggNames.length === 0 || selectMetricsNames.length === 0)) return false;
    if (Array.isArray(required) && !isContainsAnyFromArrayInOtherArray(required, selectAggNames.concat(selectMetricsNames))) return false;

    return true;
}
