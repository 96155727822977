import {FC, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/hooks";
import useOnScreen from "../../../../../../hooks/useOnScreen";

import {openPopup} from "../../../../../../redux/slices/popup";
import {projectGeneral} from "../../../../../../redux/slices/project";
import {getDashboards} from "../../../../../../actions/project";

import {PopupType} from "../../../../../../data";
import {
    formatDate,
    formattingValues,
    isNumber,
    QueueServer,
    shortenNumber
} from "../../../../../../utils";
import Loader from "../../../../../../components/Loader";

import './style.scss';
import ShortInfoOutput from "../ShortInfoOutput";
import {TIMEOUT_RETRY_LIMIT} from "../data";

interface IShortInfo {
    name:string,
    titleDefault?:string,
    projectId:number,
    periodStart:string,
    periodEnd:string,
    model?:string,
    window?:number,
    customStyleFormat?:string
    filter?:any,
    targetEvents?:any,
    queue?:QueueServer
    weight?:number,
    plan?: {
        current: number,
        previous: number,
        general?:number,
        start?:string,
        end?:string
    },
    side?: 'right'|'left'|''
}

const ShortInfo:FC<IShortInfo> = ({
    name,
    titleDefault='',
    projectId,
    periodStart,
    periodEnd,
    model,
    window,
    filter,
    targetEvents,
    customStyleFormat,
    queue,
    weight=3,
    plan,
    side=''
}) => {
    const dispatch = useAppDispatch();
    const generalProjectInfo = useAppSelector(projectGeneral);
    const currency= generalProjectInfo && generalProjectInfo.currency || 'RUB';

    const [value, setValue] = useState<any>([0,'']);
    const [fullValue, setFullValue] = useState(0);
    const [title, setTitle] = useState<string>(titleDefault ? titleDefault : 'undefined');
    const [customStyle, setCustomStyle] = useState(customStyleFormat ? customStyleFormat : '')
    const [showLoader, setLoader] = useState(true)
    const ref = useRef<HTMLDivElement | null>(null)
    const isVisible = useOnScreen(ref);
    const [firstLoading, setFirstLoading] = useState<boolean>(false);
    const [today, setToday] = useState<string>(formatDate(new Date()));
    const [isRetry, setIsRetry] = useState<boolean>(false);
    const [timeoutRetry, setTimeoutRetry] = useState<number>(5000);
    const [sumTimeRetry, setSumTimeRetry] = useState<number>(5000);

    const retryRequest = () => {
        setTimeout(() => {
            setFirstLoading(false);
            setSumTimeRetry(sumTimeRetry + timeoutRetry);
        }, timeoutRetry);
    }

    useEffect(()=> {
        setFirstLoading(false);
    }, [])
    useEffect(() => {
        if (projectId && periodStart && periodEnd) {
            setFirstLoading(false);
        }
    },[projectId, periodStart, periodEnd, model, window, filter, targetEvents, dispatch])

    useEffect(()=> {
        if(projectId && periodStart && periodEnd && isVisible && !firstLoading) {
            setLoader(true);
            const getData = () => {
                return dispatch(getDashboards({
                    projectId: Number(projectId),
                    periodStart: periodStart,
                    periodEnd: periodEnd,
                    names: [
                        name,
                    ],
                    model: model,
                    window: window,
                    filter: filter,
                    targetEvents: targetEvents,
                    retry: isRetry
                })).then((r) => {
                    if(r.payload && (r.payload === 'timeout of 30000ms exceeded' || r.payload === 'The request is already being sent')) {
                        if(sumTimeRetry <= TIMEOUT_RETRY_LIMIT) {
                            setIsRetry(true);
                            retryRequest();
                            return;
                        }
                    }
                    if (r.payload && r.payload[name]) {
                        try {
                            onShortInfo(r.payload[name], setValue, setTitle);
                        } catch (e) {
                            console.log('Error', e);
                        }
                    } else {
                        setValue([0, '']);
                    }
                    setLoader(false);
                })
            }
            if(queue) {
                queue.enqueue(() => {
                    return getData();
                }, weight);
            } else {
                getData();
            }
            setFirstLoading(true);
        }
    },[projectId, periodStart, periodEnd, model, window, filter, targetEvents, isVisible,firstLoading])

    const onShortInfo = (payload:any, setValue:any, setTitle:any) => {
        if(typeof payload.error === 'string') {
            dispatch(openPopup({ type: PopupType.ERROR, description: payload.error }));
        } else if(payload.data) {
            try {
                const data = payload.data.dataset[0] && payload.data.dataset[0][0] ? payload.data.dataset[0][0] : 0;
                const title = payload.item_title;
                const type = payload.data.column[0].type;
                if(typeof type === 'string') {
                    setValue(isNumber(data) ? formattingValues(+data, type, currency,true) : [0,'']);
                    setFullValue(+data);
                    if(type === 'percent' || type === 'currency') {
                        setCustomStyle('percent');
                    }
                } else if(isNumber(data)){
                    setValue(shortenNumber(+data, true, true));
                    setFullValue(+data);
                } else setValue([0,'']);
                if(title)
                    setTitle(title);
            } catch (e) {
                console.log('Error', e);
            }
        } else {
            setValue([0,'']);
        }
    }

    return(
        <div className="dashboards-short-info" ref={ref}>
            <ShortInfoOutput
                title={title}
                value={value}
                fullValue={fullValue}
                plan={plan}
                side={side}
                today={today}
                customStyle={customStyle}
            />
            { showLoader &&
                <Loader inBlock={true} />
            }
        </div>
    )
}

export default ShortInfo;